import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { validatedToken } from "../../features/user/userActions";
const RequireAuth = () => {
    const token = JSON.parse(localStorage.getItem('authToken'));
    const location = useLocation();
    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await validatedToken(token)

                if (!response.status == 200) {
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('authUser');
                }
            } catch (error) {
                console.error('Token validation failed:', error);
                localStorage.removeItem('authToken');
                localStorage.removeItem('authUser');
            }
        };

        if (token) {
            validateToken();
        }
    }, [token]);

    return (
        token
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;
