const Index = () => {
    return (
        <li className="nav-item dropdown dropdown-app">
            <a className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                data-bs-toggle="dropdown" href="javascript:;"><i className='bx bx-grid-alt'></i></a>
            <div className="dropdown-menu dropdown-menu-end p-0">
                <div className="app-container p-2 my-2">
                    <div className="row gx-0 gy-2 row-cols-3 justify-content-center p-2">
                        <div className="col">
                            <a href="https://bakupower.az/">
                                <div className="app-box text-center" target="_blank">
                                    <div className="app-icon">
                                        <img style={{ backgroundColor: "#28AF4A", padding: '4px', borderRadius: '7px' }} src="/assets/images/app/bakuPower.png" width="40"
                                            alt="" />
                                    </div>
                                    <div className="app-name">
                                        <p className="mb-0 mt-1">WebSite</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://www.instagram.com/" target="_blank">
                                <div className="app-box text-center">
                                    <div className="app-icon " >
                                        <img style={{ borderRadius: '7px' }} src="/assets/images/app/instagram.jpeg" width="30"
                                            alt="" />
                                    </div>
                                    <div className="app-name">
                                        <p className="mb-0 mt-1">Instagram</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://www.facebook.com/bakupower.az/" target="_blank">
                                <div className="app-box text-center">
                                    <div className="app-icon">
                                        <img src="/assets/images/app/fb.png" width="30"
                                            alt="" />
                                    </div>
                                    <div className="app-name">
                                        <p className="mb-0 mt-1">Facebook</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://www.linkedin.com/company/baku-power/" target="_blank">
                                <div className="app-box text-center">
                                    <div className="app-icon">
                                        <img src="/assets/images/app/linkedin.png" width="30"
                                            alt="" />
                                    </div>
                                    <div className="app-name">
                                        <p className="mb-0 mt-1">linkedin</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://youtu.be/pr-JfSdRy_M?si=0XTSU4k5E3rfmW4k" target="_blank">
                                <div className="app-box text-center">
                                    <div className="app-icon">
                                        <img src="/assets/images/app/youtube.png" width="30"
                                            alt="" />
                                    </div>
                                    <div className="app-name">
                                        <p className="mb-0 mt-1">YouTube</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default Index