import FileButton from "./fileButton"
import ExportPDF from "./exportPDF"
import "./style.css"
const Index = ({ data, headers }) => {

    return (
        <>
            <div className="col-sm-12 col-md-6">
                <div className="dt-buttons btn-group">
                    <ExportPDF data={data} headers={headers} />
                    <FileButton data={data} />
                </div>
            </div>
        </>
    )
}

export default Index