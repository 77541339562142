import apiClient from '../../utils/axiosConfig';
import useSWR, { mutate } from 'swr';
import { removeLocalStorage, setLocalStorage } from '../../utils/cookieUtils';



export const addUser = async (user) => {

    const response = await apiClient.post('/users', user);
    mutate('/users');

    return response.data;
};


export const updateUser = async (id, formData) => {
    const response = await apiClient.put(`/users/${id}`, formData);
    mutate('/users');
    return response.data;
};

export const updateUserProfile = async (formState, UserId) => {
    const formData = new FormData();
    Object.keys(formState).forEach(key => {
        if (key === 'profile_picture' && formState[key] instanceof File) {
            formData.append('profile_picture', formState[key]);
        } else if (formState[key] !== null && formState[key] !== undefined && formState[key] !== '') {
            formData.append(key, formState[key]);
        }
    });
    try {
        const response = await apiClient.post(`/update-profil/${UserId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        mutate('/users');
        return response.data;

    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
};


export const updateUserProfileImg = async (user) => {
    const response = await apiClient.put(`/update-profil-img/${user.id}`, user);
    mutate('/users');
    return response.data;
};

export const removeProfil = async (user) => {
    const response = await apiClient.post(`/remove-profil-img/${user.id}`, user);
    mutate('/users');
    return response;
};
export const deleteUser = async (userId) => {
    await apiClient.delete(`/users/${userId}`);
    mutate('/users');
    return userId;
};

export const getUserById = async (userId) => {
    const response = await apiClient.get(`/users/${userId}`);
    return response.data;
};

export const setUserRoles = async (userId, roles) => {
    const response = await apiClient.put(`/set-user-role/${userId}`, roles);
    mutate('/users');
    return response.data;
};

export const logout = async () => {
    try {
        const response = await apiClient.post(`/logout`);
        removeLocalStorage('authToken');
        removeLocalStorage('authUser');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "Logout failed");
    }
};

export const validatedToken = async (token) => {
    const response = await apiClient.post(`/validate-token/${token}`);
    return response;
};

export const searchUser = async (keyword) => {
    try {
        const url = keyword ? `/search/user/${keyword}` : '/users';
        const result = await apiClient.get(url);
        mutate('/users', result.data, false);
    } catch (err) {
        console.error("Search error:", err.response?.data?.message || err.message);
        throw err;
    }
};
