import { useEffect, useState } from "react";
import { useLogs } from "../../features/logs/useLogs";
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import "./style.css";

const Index = ({ text, userId }) => {
    // const itemsPerPage = 10;
    // const { t } = useTranslation();
    // const [logs, setLogs] = useState([]);
    // const { getLogsByUserId, getAllLogs } = useLogs();
    // const [currentPage, setCurrentPage] = useState(0);

    // useEffect(() => {
    //     const fetchLogs = async () => {
    //         if (userId) {
    //             const logs = await getLogsByUserId(userId);
    //             setLogs(logs);
    //         } else {
    //             const logs = await getAllLogs();
    //             setLogs(logs);
    //         }
    //     };
    //     fetchLogs();
    // }, [userId]);

    // const handlePageClick = (event) => {
    //     setCurrentPage(event.selected);
    // };

    // const offset = currentPage * itemsPerPage;
    // const currentLogs = logs.slice(offset, offset + itemsPerPage);
    // const pageCount = Math.ceil(logs.length / itemsPerPage);

    return (
        <>
            {/* <div className="col">
                <div className="modal fade custom-fullModal" id="exampleFullScreenModal2" >
                    <div className="modal-dialog modal-fullscreen custom-fullModal">
                        <div className="modal-content custom-fullModal">
                            <div className="modal-header custom-fullModal">
                                <h5 className="modal-title logs-header">{text}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body custom-fullModal">
                                <div className="">
                                    <h6 className="mb-0 text-uppercase">{t('logs.logsTable')}</h6>
                                    <hr />
                                    <div className="card">
                                        <div className="card-body table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">{t('logs.traceNumber')}</th>
                                                        <th scope="col">{t('logs.filePath')}</th>
                                                        <th scope="col">{t('logs.method')}</th>
                                                        <th scope="col">{t('logs.originalLog')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentLogs.length > 0 ? (
                                                        currentLogs.map((log, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{offset + index + 1}</th>
                                                                <td>{log.address}</td>
                                                                <td>{log.area}</td>
                                                                <td>{log.name}</td>
                                                                <td>{log.name}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4">{t('logs.noLogFound')}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-flex">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('logs.close')}</button>
                                    <div className="row paginate">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info" id="example_info" role="status" aria-live="polite">
                                                Showing {offset + 1} to {Math.min(offset + itemsPerPage, logs.length)} of {logs.length} entries
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers" id="example_paginate">
                                                <ReactPaginate
                                                    breakLabel={t('breakLabel')}
                                                    nextLabel={t('next')}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={t('previous')}
                                                    renderOnZeroPageCount={null}
                                                    containerClassName="pagination"
                                                    pageLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    activeClassName="active"
                                                    disabledClassName="disabled"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default Index;
