import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useCurrentLang = () => {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
        const storedLanguage = localStorage.getItem('i18nextLng');
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
            setLang(storedLanguage); 
        }
    }, [i18n]);

    return lang;
};

export default useCurrentLang;
