import { useState } from 'react';
import { useServicePoints } from '../../../features/servicePoint/useServicePoints';

export const useOnSavePostClicked = (formState = {}, imageUrl) => {
    const { createServicePoint } = useServicePoints();
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);

    const onSavePostClicked = async () => {
       
        setLoading(true);
        try {
            const formData = {
                name: formState.name,
                image: imageUrl || ' ',
                servicepoint_id: formState.servicepoint_id,
                servicepoint_type_id: formState.servicepoint_type_id,
                user_id: formState.user_id,
                address: formState.address,
                area: formState.area,
                remark: formState.remark,
                renting_info: formState.renting_info,
                agent_info: formState.agent_info,
                founder_info: formState.founder_info,
                opening_time: formState.opening_time,
                closing_time: formState.closing_time,
                phone: formState.phone,
                storekeeper_phone: formState.storekeeper_phone,
                salesperson_number: formState.salesperson_number,
                latitude: formState.latitude,
                longitude: formState.longitude,
            };
            const response = await createServicePoint(formData);
            setResponse(response);
        } catch (error) {
            const errorResponse = error?.response?.data || { message: 'Bilinmeyen hata oluştu' };
            setResponse(errorResponse);
            return errorResponse;
        } finally {
            setLoading(false);
        }
    };

    return {
        response,
        loading, 
        onSavePostClicked,
    };
};
