import React, { useState, useEffect, useRef } from 'react';
import './style.css';

const MultipleSelect = ({ options = [], onSelectionChange, label, name, selectedValues = [], all = false }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState(selectedValues ? selectedValues : []);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    };

    const handleItemSelect = (item) => {
        if (item === 'all') {
            const allIds = options.map(option => option.id);
            setSelectedItems(allIds);
            onSelectionChange(allIds);
        } else {
            if (selectedItems.includes(item.id)) {
                alert('Item already selected.');
                return;
            }
            const updatedSelection = [...selectedItems, item.id];
            setSelectedItems(updatedSelection);
            onSelectionChange(updatedSelection);
        }
        setDropdownOpen(false);
    };

    const removeItem = (itemId) => {
        const updatedSelection = selectedItems.filter(id => id !== itemId);
        setSelectedItems(updatedSelection);
        onSelectionChange(updatedSelection);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    return (
        <div className="multiple-select" ref={dropdownRef}>
            <label htmlFor={name} className="form-label">{label}</label>
            <div className="input-container">
                <input
                    name={name}
                    value={selectedItems.length === options.length ? 'All' : selectedItems.join(', ')}
                    type="text"
                    className="form-control"
                    id={name}
                    onClick={toggleDropdown}
                    readOnly
                />
                {dropdownOpen && (
                    <div className="dropdown-menu">
                        {all && (
                            <button
                                type="button"
                                className="dropdown-item"
                                onClick={() => handleItemSelect('all')}
                            >
                                All
                            </button>
                        )}
                        {options.map(option => (
                            <button
                                key={option.id}
                                type="button"
                                className="dropdown-item"
                                onClick={() => handleItemSelect(option)}
                            >
                                {option.name['az']}
                            </button>
                        ))}
                    </div>
                )}
                <div className="selected-items">
                    {selectedItems.length === options.length ? (
                        <div className="item-tag">
                            All
                            <span className="remove-icon" onClick={() => {
                                setSelectedItems([]);
                                onSelectionChange([]);
                            }}>x</span>
                        </div>
                    ) : (
                        selectedItems.map((itemId, index) => {
                            const selectedItem = options.find(option => option.id === itemId);
                            return (
                                <div key={index} className="item-tag">
                                    {selectedItem ? selectedItem.name['az'] : itemId}
                                    <span className="remove-icon" onClick={() => removeItem(itemId)}>x</span>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        </div>
    );
};

export default MultipleSelect;