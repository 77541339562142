import React from 'react';
import './style.css';

const Loading = ({ isLoading, isError }) => {
    if (isLoading) {
        return (
            <div className="overlay">
                <div className="spinner-wrapper">
                    <div className="spinner-border" style={{ width: "3rem", height: "3rem" }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }

    if (isError) {
        return (
            <div className="overlay">
                <div className="error-wrapper">
                    <h3>Something went wrong. Please try again later.</h3>
                </div>
            </div>
        );
    }

    return null;
};

export default Loading;
