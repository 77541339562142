import React, { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultipleSelect from '../../components/common/MultipleSelect/MultipleSelect';
import Loading from '../../components/loading';
import ToastNotification from '../../components/toastNotfication';
import { useAdvertisements } from '../../features/advertisements/useAdvertisements';
import useImageUpload from '../../features/fileUplad/useImageUpload';
import { useServicePoints } from '../../features/servicePoint/useServicePoints';
import useCurrentLang from '../../hook/useCurrentLang';
import { useHandleInputChange } from '../../hook/useHandleInputChange';
import useFetchData from './hook/useFetchData';
import { useOnUpdatePostClicked } from './hook/useOnUpdatePostClicked';
import { initialFormState } from './initialStates/update';
const Update = () => {
    const lang = useCurrentLang();
    const [activeTab, setActiveTab] = useState('az');
    const languages = ['az', 'en', 'ru']
    const { t } = useTranslation();
    const location = useLocation();
    const { advertisement } = location.state || {};
    const id = advertisement?.id;
    const { getAdvertisementById } = useAdvertisements();
    const { servicePoints } = useServicePoints();
    const servicePointsList = Array.isArray(servicePoints) ? servicePoints : [];
    const { uploadError, imageUrl, isLoading, handleFileChange } = useImageUpload();
    const { formStateById, fetching, fetchError } = useFetchData(getAdvertisementById, id, initialFormState, lang);
    const { formState, handleInputChange } = useHandleInputChange(formStateById, fetching);
    const { response, loading, onUpdatePostClicked } = useOnUpdatePostClicked(formState, imageUrl, id);
    const handleServicePointChange = (selected) => {
        handleInputChange({ target: { name: 'servicepoint_ids', value: selected } });
    };
    if (fetching) {
        return <Loading isLoading={fetching} />;
    }
    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <h6 className="mb-0 text-uppercase table-name">{t("table.updateAdvertisement")}</h6>
                            <hr />
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <ul className="nav nav-tabs">
                                            {languages.map(lang => (
                                                <li className="nav-item" key={lang}>
                                                    <button
                                                        className={`nav-link ${activeTab === lang ? 'active' : ''}`}
                                                        onClick={() => setActiveTab(lang)}
                                                    >
                                                        {lang.toUpperCase()}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className="p-4 border rounded">
                                        <form className="row g-3">
                                            <div className="col-md-4">
                                                <label htmlFor={`name-${activeTab}`} className="form-label">
                                                    {t('properties.name')}
                                                    ({activeTab})
                                                </label>
                                                <input
                                                    name='name'
                                                    onChange={(e) => handleInputChange(e, activeTab)}
                                                    value={formState.name?.[activeTab] || ''}
                                                    type="text"
                                                    className='form-control'
                                                    id="name"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="type" className="form-label">{t('properties.type')}</label>
                                                <select
                                                    name='type'
                                                    onChange={handleInputChange}
                                                    value={formState.type}
                                                    className={`form-select`}
                                                    id="type"
                                                >
                                                    <option value="image">{t('media.image')}</option>
                                                    <option value="video">{t('media.video')}</option>
                                                </select>
                                            </div>



                                            <div className="col-md-4">
                                                <label htmlFor="file_path" className="form-label">{t('media.file')}</label>
                                                <input
                                                    name='file_path'
                                                    onChange={handleFileChange}
                                                    type="file"
                                                    className={`form-control`}
                                                    id="file_path"
                                                />
                                            </div>


                                            <MultipleSelect
                                                options={servicePointsList}
                                                onSelectionChange={handleServicePointChange}
                                                selectedValues={formState.servicepoint_ids ?? formStateById?.servicepoint_ids}
                                                label={t('servicePoint.servicePoints')}
                                                name="servicepoint_ids"
                                                all={true}
                                            />

                                            <div className="col-12">
                                                <button
                                                    disabled={loading || isLoading}
                                                    className="btn btn-primary px-5"
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();  
                                                        onUpdatePostClicked();  
                                                    }}
                                                >
                                                    {loading || isLoading ? t('media.saving') + '...' : t('properties.save')}
                                                </button>
                                            </div>
                                        </form>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastNotification response={response || uploadError || fetchError} />
        </HelmetProvider>
    );
};

export default Update;