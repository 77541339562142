import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const Index = ({ data }) => {
    const handleExportExcel = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });
        saveAs(blob, 'data.xlsx');
    };

    return (
        <button
            className="btn btn-light mt-btn export-buttons"
            tabIndex="0"
            aria-controls="example2"
            type="button"
            onClick={handleExportExcel}
        >
            <span>Excel</span>
        </button>
    );
}

export default Index;
