import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; // useNavigate'yi ekleyin
import './style.css';

const Index = () => {
    const { t } = useTranslation();
    const navigate = useNavigate(); // useNavigate hook'unu çağırın

    // Ev sayfasına yönlendiren fonksiyon
    const goHome = () => {
        navigate('/'); // Ev sayfası için yönlendirme
    };

    // Önceki sayfaya yönlendiren fonksiyon
    const goBack = () => {
        navigate(-1); // Tarayıcı geçmişinde bir adım geri git
    };

    return (
        <div className="wrapper">
            <div className="error-404 d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="card">
                        <div className="row g-0">
                            <div className="col-xl-5">
                                <div className="card-body p-4">
                                    <h1 className="display-1"><span className="text-white">5</span><span className="text-white">0</span><span className="text-white">0</span></h1>
                                    <h2 className="font-weight-bold display-4">Sorry, unexpected error</h2>
                                    <p>Looks like you are lost!
                                        <br />Maybe you are not connected to the internet!</p>
                                    <div className="mt-5">
                                        <button onClick={goHome} className="btn btn-lg btn-light px-md-5 radius-30">Go Home</button>
                                        <button onClick={goBack} className="btn btn-lg btn-white ms-3 px-md-5 radius-30">Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7">
                                <img src="assets/images/errors-images/505-error.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Index;
