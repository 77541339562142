import useSWR from 'swr';
import apiClient from '../../utils/axiosConfig';

const fetcher = url => apiClient.get(url).then(res => res.data);

export const useUsers = () => {
    const { data, error, mutate } = useSWR('/users', fetcher, { revalidateOnFocus: false });

    return {
        users: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    };
};
