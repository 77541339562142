import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { STORAGE_URL } from "../config";
import React, { useEffect, useState } from "react";


const PrimaryMenu = () => {
    const { t } = useTranslation();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {

        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="primary-menu">
            <nav className={`navbar navbar-expand-lg align-items-center ${isScrolled ? 'bg-dark' : ''}`}>
                <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header border-bottom">
                        <div className="d-flex align-items-center">
                            <div className="">
                                <img src="/assets/images/app/bakuPower.png" className="logo-icon" alt="logo icon" />
                            </div>
                            <div className="">
                                <h4 className="logo-text">Baku Power</h4>
                            </div>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav align-items-center flex-grow-1">
                            <li className="nav-item">
                                <Link
                                    to="/"
                                    className="nav-link"
                                >
                                    <div className="parent-icon">
                                        <i className='bx bx-home-alt'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">{t('navbar.dashboard')}</div>
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                    <div className="parent-icon"><i className='bx bx-cube'></i></div>
                                    <div className="menu-title d-flex align-items-center">{t('navbar.users')}</div>
                                    <div className="ms-auto dropy-icon"><i className='bx bx-chevron-down'></i></div>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link to={'/user/list'} className="dropdown-item"><i className="fadeIn animated bx bx-list-ul"></i>{t('navbar.users')}</Link></li>
                                    <li><Link to={'/user/create-user'} className="dropdown-item"><i className="fadeIn animated bx bx-list-plus"></i>{t('navbar.createUser')}</Link></li>
                                    <li><Link to={'/role/list'} className="dropdown-item"><i className="fadeIn animated bx bx-list-ul"></i>{t('navbar.allRoles')}</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                    <div className="parent-icon"><i className='bx bx-cube'></i></div>
                                    <div className="menu-title d-flex align-items-center">{t('navbar.servicePoints')}</div>
                                    <div className="ms-auto dropy-icon"><i className='bx bx-chevron-down'></i></div>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link to={'/service-point/list'} className="dropdown-item"><i className="fadeIn animated bx bx-list-ul"></i>{t('navbar.servicePoints')}</Link></li>
                                    <li><Link to={'/service-point/create'} className="dropdown-item"><i className="fadeIn animated bx bx-list-plus"></i>{t('navbar.createServicePoint')}</Link></li>

                                    <li><Link to={'/service-point-type/list'} className="dropdown-item"><i className="fadeIn animated bx bx-list-ul"></i>{t('properties.servicePointTypes')}</Link></li>
                                    <li><Link to={'/service-point-type/create'} className="dropdown-item"><i className="fadeIn animated bx bx-list-plus"></i>{t('properties.createServicePointType')}</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                    <div className="parent-icon"><i className='bx bx-cube'></i></div>
                                    <div className="menu-title d-flex align-items-center">{t('navbar.advertisements')}</div>
                                    <div className="ms-auto dropy-icon"><i className='bx bx-chevron-down'></i></div>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link to={'/advertisements/list'} className="dropdown-item"><i className="fadeIn animated bx bx-list-ul"></i>{t('navbar.advertisements')}</Link></li>
                                    <li><Link to={'/advertisements/create'} className="dropdown-item"><i className="fadeIn animated bx bx-list-plus"></i>{t('navbar.createAdvertisement')}</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                    <div className="parent-icon"><i className='bx bx-cube'></i></div>
                                    <div className="menu-title d-flex align-items-center">{t('clients.clients')}</div>
                                    <div className="ms-auto dropy-icon"><i className='bx bx-chevron-down'></i></div>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link to={'/client/list'} className="dropdown-item"><i className="fadeIn animated bx bx-list-ul"></i>{t('clients.clients')}</Link></li>
                                    <li><Link to={'/client/create-client'} className="dropdown-item"><i className="fadeIn animated bx bx-list-plus"></i>{t('clients.createNewClients')}</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                    <div className="parent-icon"><i className='bx bx-cube'></i></div>
                                    <div className="menu-title d-flex align-items-center">{t('properties.cabinet')}</div>
                                    <div className="ms-auto dropy-icon"><i className='bx bx-chevron-down'></i></div>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link to={'/cabinet/list'} className="dropdown-item"><i className="fadeIn animated bx bx-list-ul"></i>{t('properties.cabinet')}</Link></li>
                                    <li><Link to={'/cabinet/create-cabinet'} className="dropdown-item"><i className="fadeIn animated bx bx-list-plus"></i>{t('properties.createNew')}</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                    <div className="parent-icon"><i className='bx bx-cube'></i></div>
                                    <div className="menu-title d-flex align-items-center">{t('orders.orders')}</div>
                                    <div className="ms-auto dropy-icon"><i className='bx bx-chevron-down'></i></div>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link to={'/order/list'} className="dropdown-item"><i className="fadeIn animated bx bx-list-ul"></i>{t('orders.ordersTable')}</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default PrimaryMenu