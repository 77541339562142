import { useState } from 'react';
import apiClient from '../../utils/axiosConfig';

const useImageUpload = () => {
    const [file, setFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            setIsLoading(true);
            try {
                const formData = new FormData();
                formData.append('image', selectedFile, selectedFile.name);
                try {
                    const response = await apiClient.post('/upload-image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    setImageUrl(response.data.data.image_url);
                    setUploadError(null);
                } catch (error) {
                    setUploadError(error.response.data);
                }
            } catch (error) {
                setUploadError('Error uploading file');
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return {
        file,
        uploadError,
        imageUrl,
        isLoading,
        handleFileChange,
    };
};

export default useImageUpload;