import { useState } from 'react';
import { useAdvertisements } from '../../../features/advertisements/useAdvertisements';

export const useOnUpdatePostClicked = (formState = {}, imageUrl, id) => {
    
    const { updateAdvertisement } = useAdvertisements();
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);

    const onUpdatePostClicked = async () => {
        setLoading(true);
        try {
            const formData = {
                id: formState.id,
                name: formState.name,
                file_type: formState.file_type,
                servicepoint_ids: formState.servicepoint_ids,
                file_path: imageUrl || formState.file_path,
            };
            const response = await updateAdvertisement(id, formData);
            setResponse(response);
        } catch (error) {
            const errorResponse = error?.data?.errors || { message: 'Bilinmeyen hata oluştu' };
            setResponse(errorResponse);
            return errorResponse;
        } finally {
            setLoading(false);
        }
    };

    return {
        response,
        loading,
        onUpdatePostClicked,
    };
};
