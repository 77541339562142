import { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHandleInputChange } from '../../hook/useHandleInputChange';
import { useOnSavePostClicked } from './hook/useOnSavePostClicked';
import ToastNotfication from '../../components/toastNotfication';
import { initialState } from './initialStates/create';
import 'react-toastify/dist/ReactToastify.css';
const Index = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('az');
    const languages = ['az', 'en', 'ru'];
    const { formState, handleInputChange } = useHandleInputChange(initialState);
    const { response, loading, onSavePostClicked } = useOnSavePostClicked(formState);
    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <ul className="nav nav-tabs">
                                            {languages.map(lang => (
                                                <li className="nav-item" key={lang}>
                                                    <button
                                                        className={`nav-link ${activeTab === lang ? 'active' : ''}`}
                                                        onClick={() => setActiveTab(lang)}
                                                    >
                                                        {lang.toUpperCase()}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="p-4 border rounded">
                                        <form className="row g-3" >
                                            <div className="col-md-12">
                                                <label htmlFor={`name-${activeTab}`} className="form-label">
                                                    {t('properties.name')} ({activeTab})
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`name-${activeTab}`}
                                                    name="name"
                                                    value={formState.name?.[activeTab] || ''}
                                                    onChange={(e) => handleInputChange(e, activeTab)}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    type="button"
                                                    onClick={onSavePostClicked}
                                                    className="btn btn-primary px-4"
                                                    disabled={ loading}
                                                >
                                                    {loading ? "Loading ..." : t('properties.save')}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastNotfication response={response} />
        </HelmetProvider>
    );
};

export default Index;