import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { getUserById, updateUser } from '../../features/user/userActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
const Update = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { user } = location.state || {};
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        status: 'active'

    });
    const [loading, setLoading] = useState(true);
    const [formError, setFormError] = useState({});
    const storedUserId = user?.id;

    useEffect(() => {
        const fetchUser = async () => {
            try {
                if (!storedUserId) {
                    throw new Error('User ID not found');
                }
                const userData = await getUserById(storedUserId);
                if (userData) {
                    setFormData({
                        id: userData.id,
                        name: userData.name || '',
                        email: userData.email || '',
                        phone: userData.phone || '',
                        address: userData.address || '',
                        status: userData.status === 1 ? 'active' : 'inactive',
                    });
                } else {
                    throw new Error('Failed to fetch user data.');
                }
            } catch (err) {
                toast.error(`Error: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [storedUserId]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setFormError({});

        const formDataObj2 = {
            id: formData.id,
            name: formData.name,
            email: formData.email,
            address: formData.address,
            phone: formData.phone,
            status: formData.status,
        };

        try {
            await updateUser(formData.id, formDataObj2);
            toast.success('User updated successfully!');
        } catch (err) {
            if (err.response && err.response.data) {
                setFormError(err.response.data.errors || {}); // Set validation errors
                toast.error('Failed to update user. Please check the form for errors.');
            } else {
                setFormError({ general: 'An unexpected error occurred.' }); // General error message
                toast.error('An unexpected error occurred.');
            }
            console.error('Failed to update the user', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <h6 className="mb-0 text-uppercase table-name">{t('properties.updateUser')}</h6>
                            <hr />
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-4 border rounded">
                                        <form className="row g-3" onSubmit={handleSubmit}>
                                            <div className="col-md-4">
                                                <label htmlFor="validationServer01" className="form-label">{t('properties.name')}</label>
                                                <input
                                                    name='name'
                                                    onChange={handleChanges}
                                                    value={formData.name}
                                                    type="text"
                                                    className={`form-control ${formError.name ? 'is-invalid' : ''}`}
                                                    id="validationServer01"
                                                />
                                                {formError.name && <div className="invalid-feedback">{formError.name.join(', ')}</div>}
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="validationServer02" className="form-label">{t('properties.email')}</label>
                                                <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend3">@</span>
                                                    <input
                                                        name='email'
                                                        onChange={handleChanges}
                                                        value={formData.email}
                                                        type="email"
                                                        className={`form-control ${formError.email ? 'is-invalid' : ''}`}
                                                        id="validationServer02"
                                                        aria-describedby="inputGroupPrepend3 validationServer02Feedback"
                                                    />
                                                    {formError.email && <div id="validationServer02Feedback" className="invalid-feedback">{formError.email.join(', ')}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="validationServer03" className="form-label">{t('properties.phone')}</label>
                                                <input
                                                    name='phone'
                                                    onChange={handleChanges}
                                                    value={formData.phone}
                                                    type="number"
                                                    className={`form-control ${formError.phone ? 'is-invalid' : ''}`}
                                                    id="validationServer03"
                                                />
                                                {formError.phone && <div className="invalid-feedback">{formError.phone.join(', ')}</div>}
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="validationServer04" className="form-label">{t('properties.address')}</label>
                                                <input
                                                    name='address'
                                                    onChange={handleChanges}
                                                    value={formData.address}
                                                    type="text"
                                                    className={`form-control ${formError.address ? 'is-invalid' : ''}`}
                                                    id="validationServer04"
                                                />
                                                {formError.address && <div className="invalid-feedback">{formError.address.join(', ')}</div>}
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="statusSelect" className="form-label">{t('properties.status')}</label>
                                                <select
                                                    name='status'
                                                    onChange={handleChanges}
                                                    value={formData.status}
                                                    className={`form-select ${formError.status ? 'is-invalid' : ''}`}
                                                    id="statusSelect"
                                                >
                                                    <option value="active">{t('properties.active')}</option>
                                                    <option value="inactive">{t('properties.inactive')}</option>
                                                </select>
                                                {formError.status && <div className="invalid-feedback">{formError.status.join(', ')}</div>}
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-light mt-btn" disabled={loading}>{t('properties.save')}</button>
                                                {loading && <p>{t('common.loading')}...</p>}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </HelmetProvider>
    );
}

export default Update;