export const initialState = {
    name: {
        az: '',
        en: '',
        ru: ''
    },
    image: '',
    servicepoint_id: '',
    user_id: '',
    servicepoint_type_id: null,
    opening_time: '',
    closing_time: '',
    address: {
        az: '',
        en: '',
        ru: ''
    },
    area: {
        az: '',
        en: '',
        ru: ''
    },
    remark: {
        az: '',
        en: '',
        ru: ''
    },
    renting_info: {
        az: '',
        en: '',
        ru: ''
    },
    agent_info: {
        az: '',
        en: '',
        ru: ''
    },
    founder_info: {
        az: '',
        en: '',
        ru: ''
    },
    opening_time: '',
    closing_time: '',
    phone: '',
    storekeeper_phone: '',
    salesperson_number: '',
    latitude: null,
    longitude: null
};
