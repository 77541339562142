import React, { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { addUser } from '../../features/user/userActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
const Create = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        password: '',
        password_confirmation: '',
        status: 'active',
    });
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState({});

    const handleChanges = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files[0]
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setFormError({});

        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'roles') {
                formData.roles.forEach(role => formDataToSend.append('roles[]', role));
            } else if (formData[key] !== null) {
                if (key === 'status') {
                    formDataToSend.append(key, formData[key] === 'active' ? 1 : 0);
                } else {
                    formDataToSend.append(key, formData[key]);
                }
            }
        });

        try {
            await addUser(formDataToSend);
            toast.success('User created successfully!');
        } catch (err) {
            if (err.response && err.response.data) {
                setFormError(err.response.data.errors || {});
                toast.error('Failed to create user. Please check the form for errors.');
            } else {
                setFormError({ general: 'An unexpected error occurred.' });
                toast.error('An unexpected error occurred.');
            }
            console.error('Failed to create the user', err);
        } finally {
            setLoading(false);
        }
    };


    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <h6 className="mb-0 text-uppercase">{t('properties.createUser')}</h6>
                            <hr />
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-4 border rounded">
                                        <form className="row g-3" onSubmit={handleSubmit}>
                                            <div className="col-md-4">
                                                <label htmlFor="nameInput" className="form-label">{t('properties.name')}</label>
                                                <input
                                                    name='name'
                                                    onChange={handleChanges}
                                                    value={formData.name}
                                                    type="text"
                                                    className={`form-control ${formError.name ? 'is-invalid' : ''}`}
                                                    id="nameInput"
                                                />
                                                {formError.name && <div className="invalid-feedback">{formError.name.join(', ')}</div>}
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="emailInput" className="form-label">{t('properties.email')}</label>
                                                <input
                                                    name='email'
                                                    onChange={handleChanges}
                                                    value={formData.email}
                                                    type="email"
                                                    className={`form-control ${formError.email ? 'is-invalid' : ''}`}
                                                    id="emailInput"
                                                />
                                                {formError.email && <div className="invalid-feedback">{formError.email.join(', ')}</div>}
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="passwordInput" className="form-label">{t('properties.password')}</label>
                                                <input
                                                    name='password'
                                                    onChange={handleChanges}
                                                    value={formData.password}
                                                    type="password"
                                                    className={`form-control ${formError.password ? 'is-invalid' : ''}`}
                                                    id="passwordInput"
                                                />
                                                {formError.password && <div className="invalid-feedback">{formError.password.join(', ')}</div>}
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="passwordConfirmationInput" className="form-label">{t('properties.confirmPassword')}</label>
                                                <input
                                                    name='password_confirmation'
                                                    onChange={handleChanges}
                                                    value={formData.password_confirmation}
                                                    type="password"
                                                    className={`form-control ${formError.password_confirmation ? 'is-invalid' : ''}`}
                                                    id="passwordConfirmationInput"
                                                />
                                                {formError.password_confirmation && <div className="invalid-feedback">{formError.password_confirmation.join(', ')}</div>}
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="phoneInput" className="form-label">{t('properties.phone')}</label>
                                                <input
                                                    name='phone'
                                                    onChange={handleChanges}
                                                    value={formData.phone}
                                                    type="text"
                                                    className={`form-control ${formError.phone ? 'is-invalid' : ''}`}
                                                    id="phoneInput"
                                                />
                                                {formError.phone && <div className="invalid-feedback">{formError.phone.join(', ')}</div>}
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="addressInput" className="form-label">{t('properties.address')}</label>
                                                <input
                                                    name='address'
                                                    onChange={handleChanges}
                                                    value={formData.address}
                                                    type="text"
                                                    className={`form-control ${formError.address ? 'is-invalid' : ''}`}
                                                    id="addressInput"
                                                />
                                                {formError.address && <div className="invalid-feedback">{formError.address.join(', ')}</div>}
                                            </div>

                                            <div className="col-md-4">
                                                <label htmlFor="statusInput" className="form-label">{t('properties.status')}</label>
                                                <select
                                                    id="statusInput"
                                                    name="status"
                                                    className={`form-select ${formError.status ? 'is-invalid' : ''}`}
                                                    value={formData.status}
                                                    onChange={handleChanges}
                                                >
                                                    <option value="active">{t('properties.active')}</option>
                                                    <option value="inactive">{t('properties.inactive')}</option>
                                                </select>
                                                {formError.status && <div className="invalid-feedback">{formError.status.join(', ')}</div>}
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="btn btn-light" disabled={loading}>{t('properties.submitForm')}</button>
                                                {loading && <p>{t('common.loading')}...</p>}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </HelmetProvider>
    );
}

export default Create;
