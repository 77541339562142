import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const useFetchData = (fetchFunction, id, initialFormState, lang) => {
    const [formStateById, setFormStateById] = useState(initialFormState);
    const [fetching, setIsfetching] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const model = 'servicepoint'
    console.log()
    useEffect(() => {
        const fetchData = async () => {
            setIsfetching(true);
            try {
                const response = await fetchFunction(id);
                const mainDataObject = response.data[model];
                console.log(mainDataObject)
                const updatedFormState = {
                    id: mainDataObject.id,
                    name: {
                        az: mainDataObject.name?.az || '',
                        en: mainDataObject.name?.en || '',
                        ru: mainDataObject.name?.ru || ''
                    },
                    image: mainDataObject.image || '',
                    servicepoint_id: mainDataObject.servicepoint_id || '',
                    servicepoint_type_id: mainDataObject.servicepoint_type_id || null,
                    address: {
                        az: mainDataObject.address?.az || '',
                        en: mainDataObject.address?.en || '',
                        ru: mainDataObject.address?.ru || ''
                    },
                    area: {
                        az: mainDataObject.area?.az || '',
                        en: mainDataObject.area?.en || '',
                        ru: mainDataObject.area?.ru || ''
                    },
                    remark: {
                        az: mainDataObject.remark?.az || '',
                        en: mainDataObject.remark?.en || '',
                        ru: mainDataObject.remark?.ru || ''
                    },
                    renting_info: {
                        az: mainDataObject.renting_info?.az || '',
                        en: mainDataObject.renting_info?.en || '',
                        ru: mainDataObject.renting_info?.ru || ''
                    },
                    agent_info: {
                        az: mainDataObject.agent_info?.az || '',
                        en: mainDataObject.agent_info?.en || '',
                        ru: mainDataObject.agent_info?.ru || ''
                    },
                    founder_info: {
                        az: mainDataObject.founder_info?.az || '',
                        en: mainDataObject.founder_info?.en || '',
                        ru: mainDataObject.founder_info?.ru || ''
                    },
                    opening_time: mainDataObject.opening_time || '',
                    closing_time: mainDataObject.closing_time || '',
                    phone: mainDataObject.phone || '',
                    storekeeper_phone: mainDataObject.storekeeper_phone || '',
                    salesperson_number: mainDataObject.salesperson_number || '',
                    latitude: mainDataObject.latitude || null,
                    longitude: mainDataObject.longitude || null
                };
                setFormStateById(updatedFormState)
            } catch (err) {
                toast.error(`Error: ${err.message}`);
                setFetchError(err.message);
            } finally {
                setIsfetching(false);
            }
        };

        fetchData();
    }, []);
    return { formStateById, setFormStateById, fetching, fetchError };
};
export default useFetchData;
