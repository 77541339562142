import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';

// Fetcher function
const fetcher = url => apiClient.get(url).then(res => res.data);

// Custom Hook
export const useServicePoints = () => {
    const { data, error } = useSWR('/servicepoint', fetcher, { revalidateOnFocus: false });
    const createServicePoint = async (newData) => {
        try {
            const response = await apiClient.post('/servicepoint', newData);
            mutate('/servicepoint');
            return response.data
        } catch (error) {
            return error.response.data
        }
    };

    const updateServicePoint = async (id, updatedData) => {
        try {
            const response = await apiClient.put(`/servicepoint/${id}`, updatedData);
            mutate('/servicepoint');
            return response
        } catch (err) {
            return err.response.data
        }

    };

    const deleteServicePoint = async (id) => {
        try {
            const response = await apiClient.delete(`/servicepoint/${id}`);
            mutate('/servicepoint');
            return response
        } catch (err) {
            return err.response.data
        }

    };

    const getServicePointById = async (servicePointId) => {
        const response = await apiClient.get(`/servicepoint/${servicePointId}`);
        return response.data;
    };
    const searchServicePoint = async (keyword) => {
        try {
            const url = keyword ? `/search/servicePoint/${keyword}` : '/servicepoint';
            const result = await apiClient.get(url);
            mutate('/servicepoint', result.data, false);
        } catch (err) {
            console.error("Search error:", err.response?.data?.message || err.message);
            throw err;
        }
    };
    return {
        servicePoints: data?.data.servicepoints,
        isLoading: !error && !data,
        isError: error,
        createServicePoint,
        updateServicePoint,
        deleteServicePoint,
        searchServicePoint,
        getServicePointById,
        mutate
    };
};
