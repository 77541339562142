import { useState } from 'react';
import { useSerivePointTypes } from '../../../features/servicePointType/useServicePointType';

export const useOnSavePostClicked = (formState = {}) => {
    const { createSerivePointType } = useSerivePointTypes();
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);

    const onSavePostClicked = async () => {
       
        setLoading(true);
        try {
            const formData = {
                name: formState.name
            };
            const response = await createSerivePointType(formData);
            setResponse(response);
        } catch (error) {
            const errorResponse = error?.response?.data || { message: 'Bilinmeyen hata oluştu' };
            setResponse(errorResponse);
            return errorResponse;
        } finally {
            setLoading(false);
        }
    };

    return {
        response,
        loading, 
        onSavePostClicked,
    };
};
