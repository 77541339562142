import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';

// Fetcher function
const fetcher = url => apiClient.get(url).then(res => res.data);

// Custom Hook
export const useLogs = () => {
    // const { data, error } = useSWR('/logs', fetcher, { revalidateOnFocus: false });
    const { data, error } = [];

    return {
        logs: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    };
};
