import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';

const fetcher = url => apiClient.get(url).then(res => res.data);

export const useAdvertisements = () => {
    const { data, error } = useSWR('/advertisement', fetcher, { revalidateOnFocus: false });
    const createAdvertisement = async (newData) => {
        try {
            const response = await apiClient.post('/advertisement', newData);
            return response.data
        } catch (err) {
            return err.response.data
        }

    };

    const updateAdvertisement = async (id, formData) => {
        try {
            const response = await apiClient.put(`/advertisement/${id}`, formData);
            return response.data
        } catch (err) {
            return err.response.data
        }
    };

    const deleteAdvertisement = async (id) => {
        try {
            await apiClient.delete(`/advertisement/${id}`);
            mutate('/advertisement');
        } catch (err) {
            throw err;
        }
    };

    const searchAdvertisement = async (keyword) => {
        try {
            const url = keyword ? `/search/advertisement/${keyword}/${["servicepoint"]}` : '/advertisement';
            const result = await apiClient.get(url);
            mutate('/advertisement', result.data, false);
        } catch (err) {
            console.error("Search error:", err.response?.data?.message || err.message);
            throw err;
        }
    };
    const getAdvertisementById = async (advertisementId) => {
        const response = await apiClient.get(`/advertisement/${advertisementId}`);
        return response.data;
    };
    return {
        advertisements: data?.data.advertisements,
        isLoading: !error && !data,
        isError: error,
        createAdvertisement,
        updateAdvertisement,
        deleteAdvertisement,
        searchAdvertisement,
        getAdvertisementById,
        mutate
    };
};
