import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ExportButtons from "../../components/ExportButtons";
import Loading from "../../components/loading";
import Popup from '../../components/Popup/Popup';
import ToastNotification from "../../components/toastNotfication";
import { STORAGE_URL } from '../../config';
import { useServicePoints } from '../../features/servicePoint/useServicePoints';
import useCurrentLang from "../../hook/useCurrentLang";
import "./style.css";
const List = () => {
    const lang = useCurrentLang();
    console.log(lang)
    const { t } = useTranslation();
    const { servicePoints, isLoading, isError, deleteServicePoint, searchServicePoint } = useServicePoints();
    const [currentPage, setCurrentPage] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedServicePoint, setSelectedServicePoint] = useState(null);
    const itemsPerPage = 5;
    const [response, setResponse] = useState()
    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    const handleSearch = async (event) => {
        const newKeyword = event.target.value;
        setKeyword(newKeyword);
        await searchServicePoint(newKeyword);
        setCurrentPage(0);
    };

    const offset = currentPage * itemsPerPage;
    const currentServicePoints = servicePoints?.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(servicePoints?.length / itemsPerPage);

    const handleConfirm = async () => {
        if (selectedServicePoint) {
            try {
                const response = await deleteServicePoint(selectedServicePoint.id);
                setResponse(response.data)
            } catch (error) {
                setResponse(error?.data)
            }
        }
        handleClosePopup();
    };
    const handleOpenPopup = (servicePoint) => {
        setSelectedServicePoint(servicePoint);
        setIsPopupOpen(true);
    };
    const handleClosePopup = () => {
        setSelectedServicePoint(null);
        setIsPopupOpen(false);
    };
    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    if (isError) {
        return <Loading isLoading={false} isError={isError} />;
    }
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    {/* Breadcrumb and header */}
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">{t('table.tables')}</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to={"/"}>
                                            <i className="bx bx-home-alt"></i>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">{t('table.servicePoint')}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="ms-auto">
                            <div className="btn-group">
                                <Link to={"/service-point/create"} className="btn btn-light mt-btn">{t('table.addServicePoint')}</Link>
                            </div>
                        </div>
                    </div>
                    <h6 className="mb-0 text-uppercase table-name">{t('table.advertisements')}</h6>
                    <hr />
                    <div className="card">
                        <div className="card-body">
                            <div className="row margin-b">
                                <ExportButtons data={currentServicePoints} headers={["name"]} />
                                <div className="col-sm-12 col-md-6">
                                    <div id="example2_filter" className="dataTables_filter">
                                        <div className="search-wrapper">
                                            <label>{t('table.search')}:</label>
                                            <input
                                                type="search"
                                                className="form-control form-control-sm"
                                                placeholder={`${t('table.tosearch')}...`}
                                                value={keyword}
                                                onChange={handleSearch}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table id="example2" className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t('properties.image')}</th>
                                            <th>{t('properties.name')}</th>
                                            <th>{t('properties.type')}</th>
                                            <th>{t('properties.address')}</th>
                                            <th>{t('properties.area')}</th>
                                            <th>{t('properties.latitude')}</th>
                                            <th>{t('properties.longitude')}</th>
                                            <th>{t('properties.phone')}</th>
                                            <th>{t('properties.storekeeper_phone')}</th>
                                            <th>{t('properties.salesperson_number')}</th>
                                            <th>{t('properties.remark')}</th>
                                            <th>{t('properties.opening_time')}</th>
                                            <th>{t('properties.closing_time')}</th>
                                            <th>{t('properties.renting_info')}</th>
                                            <th>{t('properties.agent_info')}</th>
                                            <th>{t('properties.founder_info')}</th>
                                            <th>{t('properties.status')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentServicePoints.map(servicePoint => (
                                            <tr key={servicePoint.id}>
                                                <td>{servicePoint.id}</td>
                                                <td className="custom-table-img">
                                                    {servicePoint.image ? (
                                                        <img
                                                            src={`${STORAGE_URL}${servicePoint.image}`}
                                                            alt="Service Point"
                                                            style={{
                                                                width: '100px',
                                                                borderRadius: '7px',
                                                            }}
                                                        />
                                                    ) : (
                                                        <div style={{ width: '100px', borderRadius: '7px', textAlign: 'center' }}>
                                                            {t('properties.noImage')}
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    {servicePoint.name[lang]}
                                                </td>
                                                <td>
                                                    {servicePoint.servicepoint_type_id}
                                                </td>
                                                <td>{servicePoint.address[lang]}</td>
                                                <td>{servicePoint.area[lang]}</td>
                                                <td>{servicePoint.latitude}</td>
                                                <td>{servicePoint.longitude}</td>
                                                <td>{servicePoint.phone}</td>
                                                <td>{servicePoint.storekeeper_phone}</td>
                                                <td>{servicePoint.salesperson_number}</td>
                                                <td> {servicePoint.remark[lang]}</td>
                                                <td>{servicePoint.opening_time}</td>
                                                <td>{servicePoint.closing_time}</td>
                                                <td>{servicePoint.renting_info[lang]}</td>
                                                <td>{servicePoint.agent_info[lang]}</td>
                                                <td> {servicePoint.founder_info[lang]}</td>
                                                <td>
                                                    <span className={`badge ${servicePoint.status === '1' ? 'bg-success' : 'bg-danger'}`}>
                                                        {servicePoint.status === '1' ? t('status.active') : t('status.inactive')}
                                                    </span>
                                                </td>
                                                <td>
                                                    <div className="col">
                                                        <div style={{ justifyContent: "center" }} className="d-flex align-items-center justify-content-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                                                            <div className="font-22 text-white" style={{ paddingInline: "7px" }}>
                                                                <Link to={`/service-point/show/${servicePoint.id}`} state={{ servicePoint }}>
                                                                    <i className="lni lni-pencil-alt"></i>
                                                                </Link>
                                                            </div>
                                                            <div onClick={() => handleOpenPopup(servicePoint)} className="font-22 text-white">
                                                                <i className="lni lni-trash"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>{t('properties.image')}</th>
                                            <th>{t('properties.name')}</th>
                                            <th>{t('properties.type')}</th>
                                            <th>{t('properties.address')}</th>
                                            <th>{t('properties.area')}</th>
                                            <th>{t('properties.latitude')}</th>
                                            <th>{t('properties.longitude')}</th>
                                            <th>{t('properties.phone')}</th>
                                            <th>{t('properties.storekeeper_phone')}</th>
                                            <th>{t('properties.salesperson_number')}</th>
                                            <th>{t('properties.remark')}</th>
                                            <th>{t('properties.opening_time')}</th>
                                            <th>{t('properties.closing_time')}</th>
                                            <th>{t('properties.renting_info')}</th>
                                            <th>{t('properties.agent_info')}</th>
                                            <th>{t('properties.founder_info')}</th>
                                            <th>{t('properties.status')}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-5">
                                    <div className="dataTables_info" id="example_info" role="status" aria-live="polite">
                                        Showing {offset + 1} to {Math.min(offset + itemsPerPage, servicePoints?.length)} of {servicePoints?.length} entries
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                    <div className="dataTables_paginate paging_simple_numbers" id="example_paginate">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="Next"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={pageCount}
                                            previousLabel="Previous"
                                            renderOnZeroPageCount={null}
                                            containerClassName="pagination justify-content-end"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            activeClassName="active"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  <Popup
                        text={t('common.confirmDelete')}
                        isOpen={isPopupOpen}
                        onClose={handleClosePopup}
                        onConfirm={handleConfirm}
                    />
                    <ToastContainer />
                    {/* Popup for deletion confirmation */}
                    <Popup />
                </div>
            </div>
            <ToastNotification response={response} />
        </>
    );
};

export default List;
