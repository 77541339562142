import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../components/loading';
import ToastNotification from '../../components/toastNotfication';
import useImageUpload from '../../features/fileUplad/useImageUpload';
import { useServicePoints } from '../../features/servicePoint/useServicePoints';
import useCurrentLang from '../../hook/useCurrentLang';
import { useHandleInputChange } from '../../hook/useHandleInputChange';
import useFetchData from './hook/useFetchData';
import { useOnUpdatePostClicked } from './hook/useOnUpdatePostClicked';
import { initialFormState } from './initialStates/update';
import { useSerivePointTypes } from '../../features/servicePointType/useServicePointType';
import { useUsers } from '../../features/user/useUsers';
const Update = () => {
    const [lang, setLang] = useState()
    const [activeTab, setActiveTab] = useState('az');
    const languages = ['az', 'en', 'ru']
    const { t } = useTranslation();
    const location = useLocation();
    const { servicePoint } = location.state || {};
    const id = servicePoint.id;
    const { getServicePointById, servicePoints } = useServicePoints();
    const servicePointList = Array.isArray(servicePoints) ? servicePoints : [];
    const { servicePointTypes } = useSerivePointTypes();
    const servicePointTypesList = Array.isArray(servicePointTypes) ? servicePointTypes : [];
    const { users } = useUsers();
    const usersList = Array.isArray(users) ? users : [];
    const { uploadError, imageUrl, isLoading, handleFileChange } = useImageUpload();
    const { formStateById, fetching, fetchError } = useFetchData(getServicePointById, id, initialFormState, lang);
    const { formState, handleInputChange } = useHandleInputChange(formStateById, fetching);
    const { response, loading, onUpdatePostClicked } = useOnUpdatePostClicked(formState, imageUrl, id);
    if (fetching) {
        return <Loading isLoading={fetching} />;
    }
    return (
        <HelmetProvider>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-xl-9 mx-auto">
                            <h6 className="mb-0 text-uppercase table-name">{t("table.updateAdvertisement")}</h6>
                            <hr />
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <ul className="nav nav-tabs">
                                            {languages.map(lang => (
                                                <li className="nav-item" key={lang}>
                                                    <button
                                                        className={`nav-link ${activeTab === lang ? 'active' : ''}`}
                                                        onClick={() => setActiveTab(lang)}
                                                    >
                                                        {lang.toUpperCase()}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className="p-4 border rounded">
                                        <form className="row g-3">

                                            {/* <div className="col-md-4">
                                                <label htmlFor={`name-${activeTab}`} className="form-label">
                                                    {t('properties.name')}
                                                    ({activeTab})
                                                </label>
                                                <input
                                                    name='name'
                                                    onChange={(e) => handleInputChange(e, activeTab)}
                                                    value={formState.name?.[activeTab] || ''}
                                                    type="text"
                                                    className='form-control'
                                                    id="name"
                                                />
                                            </div> */}

                                            <div className="col-md-6">
                                                <label htmlFor={`name-${activeTab}`} className="form-label">
                                                    {t('properties.name')} ({activeTab})
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`name-${activeTab}`}
                                                    name="name"
                                                    value={formState.name?.[activeTab] || ''}
                                                    onChange={(e) => handleInputChange(e, activeTab)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor={`address-${activeTab}`} className="form-label">
                                                    {t('properties.address')} ({activeTab})
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`address-${activeTab}`}
                                                    name="address"
                                                    value={formState.address?.[activeTab] || ''}
                                                    onChange={(e) => handleInputChange(e, activeTab)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor={`area-${activeTab}`} className="form-label">
                                                    {t('properties.area')} ({activeTab})
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`area-${activeTab}`}
                                                    name="area"
                                                    value={formState.area?.[activeTab] || ''}
                                                    onChange={(e) => handleInputChange(e, activeTab)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor={`remark-${activeTab}`} className="form-label">
                                                    {t('properties.remark')} ({activeTab})
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`remark-${activeTab}`}
                                                    name="remark"
                                                    value={formState.remark?.[activeTab] || ''}
                                                    onChange={(e) => handleInputChange(e, activeTab)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor={`renting_info-${activeTab}`} className="form-label">
                                                    {t('properties.renting_info')} ({activeTab})
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`renting_info-${activeTab}`}
                                                    name="renting_info"
                                                    value={formState.renting_info?.[activeTab] || ''}
                                                    onChange={(e) => handleInputChange(e, activeTab)}
                                                />
                                            </div>


                                            <div className="col-md-6">
                                                <label htmlFor={`agent_info-${activeTab}`} className="form-label">
                                                    {t('properties.agent_info')} ({activeTab})
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`agent_info-${activeTab}`}
                                                    name="agent_info"
                                                    value={formState.agent_info?.[activeTab] || ''}
                                                    onChange={(e) => handleInputChange(e, activeTab)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor={`founder_info-${activeTab}`} className="form-label">
                                                    {t('properties.founder_info')} ({activeTab})
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`founder_info-${activeTab}`}
                                                    name="founder_info"
                                                    value={formState.founder_info?.[activeTab] || ''}
                                                    onChange={(e) => handleInputChange(e, activeTab)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label">
                                                    {t('properties.phone')}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="phone"
                                                    value={formState.phone || ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label">
                                                    {t('properties.storekeeper_phone')}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="storekeeper_phone"
                                                    value={formState.storekeeper_phone || ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label">
                                                    {t('properties.salesperson_number')}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="salesperson_number"
                                                    value={formState.salesperson_number || ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label">
                                                    {t('properties.latitude')}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="latitude"
                                                    value={formState.latitude || ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label">
                                                    {t('properties.longitude')}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="longitude"
                                                    value={formState.longitude || ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label">
                                                    {t('properties.founder_info')}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="phone"
                                                    value={formState.phone || ''}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="image" className="form-label">{t('media.file')}</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="image"
                                                    onChange={handleFileChange}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="opening_time" className="form-label">{t("properties.openingTime")}</label>
                                                <input
                                                    name='opening_time'
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={formState.opening_time}
                                                    type="time"
                                                    className={`form-control`}
                                                    id="opening_time"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="closing_time" className="form-label">{t("properties.closingTime")}</label>
                                                <input
                                                    name='closing_time'
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={formState.closing_time}
                                                    type="time"
                                                    className={`form-control`}
                                                    id="closing_time"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="servicepoint_id" className="form-label">{t("properties.servicePoint")}</label>
                                                <select
                                                    name="servicepoint_id"
                                                    onChange={handleInputChange}
                                                    value={formState.servicepoint_id}
                                                    className="form-select"
                                                    id="servicepoint_id"
                                                >
                                                    <option value="">Choose...</option>
                                                    {servicePointList?.map((servicePoint) => (
                                                        <option
                                                            key={servicePoint.id}
                                                            value={servicePoint.id}
                                                        >
                                                            {servicePoint.name[lang]}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="servicepoint_type_id" className="form-label">{t("properties.servicePoint")}</label>
                                                <select
                                                    name='servicepoint_type_id'
                                                    onChange={handleInputChange}
                                                    value={formState.servicepoint_type_id}
                                                    className={`form-select`}
                                                    id="servicepoint_type_id"
                                                >
                                                    <option value=''>Choose...</option>
                                                    {servicePointTypesList?.map((servicePointType) => (
                                                        <option key={servicePointType?.id} value={servicePointType?.id}>{servicePointType.name[lang]}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="user_id" className="form-label">{t("properties.user")}</label>
                                                <select
                                                    name='user_id'
                                                    onChange={handleInputChange}
                                                    value={formState.user_id}
                                                    className={`form-select`}
                                                    id="user_id"
                                                >
                                                    <option value=''>Choose...</option>
                                                    {usersList?.map((user) => (
                                                        <option key={user?.id} value={user?.id}>{user.name}</option>
                                                    ))}
                                                </select>
                                            </div>



                                            <div className="col-12">
                                                <button
                                                    disabled={loading || isLoading}
                                                    className="btn btn-primary px-5"
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onUpdatePostClicked();
                                                    }}
                                                >
                                                    {loading || isLoading ? t('media.saving') + '...' : t('properties.save')}
                                                </button>
                                            </div>
                                        </form>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastNotification response={response || uploadError || fetchError} />
        </HelmetProvider>
    );
};

export default Update;