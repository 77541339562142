import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';

const fetcher = url => apiClient.get(url).then(res => res.data);

export const useSerivePointTypes = () => {
    const { data, error } = useSWR('/servicepoint-type', fetcher, { revalidateOnFocus: false });
    const createSerivePointType = async (newData) => {
        try {
            const response = await apiClient.post('/servicepoint-type', newData);
            return response.data
        } catch (err) {
            return err.response.data
        }

    };

    const updateSerivePointType = async (id, formData) => {
        try {
            const response = await apiClient.put(`/servicepoint-type/${id}`, formData);
            return response.data
        } catch (err) {
            return err.response.data
        }
    };

    const deleteSerivePointType = async (id) => {
        try {
            const response = await apiClient.delete(`/servicepoint-type/${id}`);
            return response;
        } catch (err) {
            return err.response.data
        }
    };

    const searchSerivePointType = async (keyword) => {
        try {
            const url = keyword ? `/search/servicepoint-type/${keyword}` : '/servicepoint-type';
            const result = await apiClient.get(url);
            mutate('/servicepoint-type', result.data, false);
        } catch (err) {
            console.error("Search error:", err.response?.data?.message || err.message);
            throw err;
        }
    };
    const getSerivePointTypeById = async (servicePoinTypeId) => {
        const response = await apiClient.get(`/servicepoint-type/${servicePoinTypeId}`);
        return response.data;
    };

    return {
        servicePointTypes: data?.data.servicepoint_types,
        isLoading: !error && !data,
        isError: error,
        createSerivePointType,
        updateSerivePointType,
        deleteSerivePointType,
        searchSerivePointType,
        getSerivePointTypeById,
        mutate
    };
};
