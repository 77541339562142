import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ApexChart1 from "../../components/apexChart1";
import LineChart from '../../components/LineChart/LineChart';
import Loading from '../../components/loading';
import PieChart from "../../components/PieChart";
import { useStatistics } from '../../features/statistics/statistics';
import useHomeLoadScripts from '../../hook/scripts/useHomeLoadScripts';
import './style.css';
import Table from "./table";
import DevicesInfo from "./devicesInfo";
const Index = () => {
    const { statistics = [], isLoading, isError } = useStatistics();
    const { t } = useTranslation();
    useHomeLoadScripts()
    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }
    if (isError) {
        return <Loading isLoading={false} isError={isError} />;
    }
    return (
        <HelmetProvider>

            <div className="page-wrapper">
                <div className="page-content">
                    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-2">
                        <div class="col">
                            <div class="card radius-10 bg-primary-custom bg-gradient">
                                <div class="card-body custom-total-amaunt">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <p class="mb-0 text-white">{t("home.totalSuccessfulOrdersAmount")} 6377</p>
                                            <h4 class="my-1 text-white">${statistics.total_paid_today}</h4>
                                        </div>
                                        <div class="text-white ms-auto font-35"><i class='bx bx-dollar'></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card radius-10 bg-danger-custom bg-gradient">
                                <div class="card-body custom-total-amaunt">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <p class="mb-0 text-white">{t("home.todaySuccessfulOrdersAmount")} </p>
                                            <h4 class="my-1 text-white">${statistics.total_paid_today}</h4>
                                        </div>
                                        <div class="text-white ms-auto font-35"><i class='bx bx-dollar'></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DevicesInfo info={statistics} />
                    <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                        <div className="col">
                            <ApexChart1 total={statistics['total_rentals']} />
                        </div>
                        <div className="col">
                            <LineChart />
                        </div>
                        <div className="col">
                            {/* <PieChart /> */}
                            <Table />
                        </div>
                        <div className="col">
                            <PieChart />
                        </div>
                    </div>

                </div>
            </div>

        </HelmetProvider>
    )
}

export default Index