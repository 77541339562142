import useSWR, {  mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';

export const useLogs = () => {
    const getLogsByUserId = async (userId) => {
        const response = await apiClient.get(`/servicepoint`);
        return response.data;
    };

    const getAllLogs = async () => {
        const response = await apiClient.get(`/servicepoint`);
        return response.data;
    };

    return {
        getLogsByUserId,
        getAllLogs
    };
};
