import { Outlet, useLocation } from 'react-router-dom';
import Header from './header/Header';
import PrimaryMenu from './PrimaryMenu';

const Layout = () => {
    const location = useLocation();

    const isRequireRolePage = location.pathname.includes('require-role');

    return (
        <>
            {!isRequireRolePage &&
                <>
                    <Header />  <PrimaryMenu />
                </>
            }

            <main className="App">
                <Outlet />
            </main>
        </>
    );
}

export default Layout;
