import useSWR, { mutate } from 'swr';
import apiClient from '../../utils/axiosConfig';

const fetcher = url => apiClient.get(url).then(res => res.data);

export const useStatistics = () => {
    const { data, error } = useSWR('/statistics', fetcher, { revalidateOnFocus: false });

  
    return {
        statistics: [],
        isLoading: !error && !data,
        isError: error,
        mutate
    };
};
