export const { initialFormState } = {
    id: '',
    file_path: null,
    name: {
        az: '',
        en: '',
        ru: ''
    },
    file_type: '',
    servicepoint_ids: []
};