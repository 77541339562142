import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "../pages/home/index";
import CreateUser from "../pages/user/index";
import UserList from "../pages/user/list";
import UserUpdate from "../pages/user/update";
import ServicePoint from "../pages/servicePoint/index";
import ServicePointType from "../pages/servicePointType/index";
import ServicePointList from "../pages/servicePoint/list";
import ServicePointTypeList from "../pages/servicePointType/list";
import RolePermissionList from "../pages/rolePermission/list";
import RolePermissionForm from "../pages/rolePermission/index";
import ServicePointUpdate from "../pages/servicePoint/update";
import ServicePointTypeUpdate from "../pages/servicePointType/update";
import AdvertisementListUpdate from "../pages/advertisements/update";
import RequireRolePage from "../pages/require-role/index";
import Login from "../pages/auth/login";
import ForgotPassword from "../pages/auth/forgotPassword";
import Layout from "../components/Layout";
import RequireAuth from "../features/auth/RequireAuth";

import { Suspense, lazy } from 'react';
import RequireRole from "../features/auth/RequireRole";

// Loadable Component Wrapper
function Loadable(Component) {
    return function (props) {
        return (
            <Suspense>
                <Component {...props} />
            </Suspense>
        );
    };
}

// Lazy Loaded Components
const LazyProfile = Loadable(lazy(() => import('../pages/profile/index')));
const LazyAdvertisement = Loadable(lazy(() => import('../pages/advertisements/index')));
const LazyAdvertisementList = Loadable(lazy(() => import('../pages/advertisements/list')));
const LazyUserRoleList = Loadable(lazy(() => import('../pages/user/setUserRoles')));
const LazyClient = Loadable(lazy(() => import('../pages/client/index')));
const LazyClientList = Loadable(lazy(() => import('../pages/client/list')));
const ClienUpdate = Loadable(lazy(() => import('../pages/client/update')));
const LazyCabinet = Loadable(lazy(() => import('../pages/cabinet/index')));
const LazyCabinetList = Loadable(lazy(() => import('../pages/cabinet/list')));
const CabinetUpdate = Loadable(lazy(() => import('../pages/cabinet/update')));
const LazyOrderList = Loadable(lazy(() => import('../pages/order/list')));
const LazyServicePointTypeList = Loadable(lazy(() => import('../pages/servicePointType/list')));

const router = createBrowserRouter([
    {
        path: "login",
        element: <Login />,
    }, {
        path: "forgot-password",
        element: <ForgotPassword />,
    },
    {
        element: <RequireAuth />,
        children: [
            {
                path: "/",
                element: <Layout />,
                children: [
                    {
                        index: true,
                        element: <Home />,
                    },
                    {
                        path: "user",
                        children: [
                            { path: "create-user", element: <CreateUser /> },
                            { path: "show-user/:userId", element: <UserUpdate /> },
                            { path: "profile", element: <LazyProfile /> },
                            { path: "list", element: <UserList /> },
                            { path: "set-user-roles/:userId", element: <LazyUserRoleList /> },
                        ],
                    },
                    {
                        path: "client",
                        children: [
                            { path: "create-client", element: <LazyClient /> },
                            { path: "show/:id", element: <ClienUpdate /> },
                            { path: "list", element: <LazyClientList /> }
                        ],
                    },
                    {
                        path: "cabinet",
                        children: [
                            { path: "create-cabinet", element: <LazyCabinet /> },
                            { path: "show/:id", element: <CabinetUpdate /> },
                            { path: "list", element: <LazyCabinetList /> }
                        ],
                    },
                    {
                        path: "order",
                        children: [
                            { path: "list", element: <LazyOrderList /> }
                        ],
                    },
                    {
                        path: "service-point",
                        children: [
                            {
                                path: "list",
                                // element: <RequireRole allowedRoles={['super-admin', 'admin', 'operator']} />,
                                children: [
                                    { path: "", element: <ServicePointList /> }
                                ]
                            },
                            {
                                path: "create",
                                // element: <RequireRole allowedRoles={['super-admin']} />,
                                children: [
                                    { path: "", element: <ServicePoint /> }
                                ]
                            },
                            {
                                path: "show/:servicepointId",
                                // element: <RequireRole allowedRoles={['super-admin', 'admin']} />,
                                children: [
                                    { path: "", element: <ServicePointUpdate /> }
                                ]
                            },
                        ],
                    },
                    {
                        path: "service-point-type",
                        children: [
                            {
                                path: "list",
                                children: [
                                    { path: "", element: <LazyServicePointTypeList /> }
                                ]
                            },
                            {
                                path: "create",
                                children: [
                                    { path: "", element: <ServicePointType /> }
                                ]
                            },
                            {
                                path: "show/:servicepointTypeId",
                                children: [
                                    { path: "", element: <ServicePointTypeUpdate /> }
                                ]
                            },
                        ],
                    }, {
                        path: "advertisements",
                        children: [
                            { path: "list", element: <LazyAdvertisementList /> },
                            { path: "create", element: <LazyAdvertisement /> },
                            { path: "show/:advertisementId", element: <AdvertisementListUpdate /> },
                        ],
                    },
                    {
                        path: "role",
                        children: [
                            { path: "list", element: <RolePermissionList /> },
                            { path: "set-role-permission/:roleId", element: <RolePermissionForm /> },
                        ]
                    },
                    {
                        path: "require-role",
                        element: <RequireRolePage />
                    },
                    {
                        path: "*",
                        element: <Navigate to="/" replace />,
                    },
                ],
            },
        ],
    },
]);

export default router;
