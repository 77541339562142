import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Index = ({ data, headers }) => {
    const handleExportPDF = (e) => {
        e.preventDefault();

        const doc = new jsPDF();

        if (data.length === 0) {
            alert("No data available for export");
            return;
        }

        // Eğer headers sağlanmışsa, bu headers'ı kullan
        const columns = headers.map(header => ({
            title: header,
            dataKey: header.toLowerCase().replace(/\s+/g, '_') // Başlıkları uygun dataKey'ler haline getir
        }));

        // Tablo verilerini hazırla
        const rows = data.map(item =>
            columns.reduce((acc, col) => {
                acc[col.dataKey] = item[col.dataKey] || ''; // Verilerde bulunmayan anahtarlar için boş string
                return acc;
            }, {})
        );

        // Tabloyu PDF'ye ekle
        doc.autoTable({
            head: [columns.map(col => col.title)],
            body: rows.map(row => columns.map(col => row[col.dataKey])),
            startY: 20
        });

        // Dosyayı kaydet
        doc.save('document.pdf');
    };

    return (
        <button
            className="btn btn-light export-buttons"
            type="button"
            onClick={handleExportPDF}
        >
            <span>PDF</span>
        </button>
    );
}

export default Index;
