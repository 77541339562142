import { useTranslation } from 'react-i18next';
const Index = ({ info }) => {
    const { t } = useTranslation();

    return (
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-4">
            <div className="col">
                <div className="card radius-10">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div>
                                <p className="mb-0">{t("home.allDevices")}</p>
                                <h4 className="my-1">{info['total_powerbanks']}</h4>
                                <p className="mb-0 font-13"><i className='bx bxs-up-arrow align-middle'></i>
                                    100%
                                </p>
                            </div>
                            <div className="widgets-icons ms-auto"><i className='bx bxs-wallet'></i>
                            </div>
                        </div>
                        <div id="chart1"></div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card radius-10">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div>
                                <p className="mb-0">{t("home.activateDevice")}</p>
                                <h4 className="my-1">{info['available_powerbanks']}</h4>
                                <p className="mb-0 font-13"><i className='bx bxs-up-arrow align-middle'></i>
                                    {
                                        info.total_powerbanks > 0
                                            ? ((info.available_powerbanks / info.total_powerbanks) * 100).toFixed(2) + "%"
                                            : "N/A"
                                    }
                                </p>
                            </div>
                            <div className="widgets-icons ms-auto"><i className='bx bxs-group'></i>
                            </div>
                        </div>
                        <div id="chart2"></div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card radius-10">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div>
                                <p className="mb-0">{t("home.activateOffline")}</p>
                                <h4 className="my-1">{info['rented_powerbanks']}</h4>
                                <p className="mb-0 font-13"><i className='bx bxs-up-arrow align-middle'></i>
                                    {
                                        info.total_powerbanks > 0
                                            ? ((info.rented_powerbanks / info.total_powerbanks) * 100).toFixed(2) + "%"
                                            : "N/A"
                                    }</p>
                            </div>
                            <div className="widgets-icons ms-auto"><i className='bx bxs-group'></i>
                            </div>
                        </div>
                        <div id="chart2"></div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card radius-10">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div>
                                <p className="mb-0">{t("home.activateOnline")}</p>
                                <h4 className="my-1">205</h4>
                                <p className="mb-0 font-13"><i className='bx bxs-down-arrow align-middle'></i>35.6%</p>
                            </div>
                            <div className="widgets-icons ms-auto"><i className='bx bxs-binoculars'></i>
                            </div>
                        </div>
                        <div id="chart3"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index